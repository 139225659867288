import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'

const HeroMobile = styled(Image)`
  display: block;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  object-position: bottom;
  @media (min-width: 1025px) {
    display: none;
  }
`

const HeroDesktop = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  display: none;
  @media (min-width: 1025px) {
    display: block;
  }
`

const BlueBannerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 124px 0;
  margin-bottom: 0px;
  @media (max-width: 1025px) {
    margin-top: 0px;
    height: calc(100vh - 70px);
    ${(props) => (props.short ? 'height: calc(70vh - 24px - 52px);' : '')}
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 1025px) {
    height: 100%;
    width: 100%;
    padding: 0;
    justify-content: space-between;
    font-size: 0.8em;
    margin-top: 17px;
    margin-bottom: 31px;
    margin-left: 0;
    padding: 8px;
  }
`

const TextTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  @media (max-width: 1025px) {
    justify-content: flex-start;
    padding-top: 27px;
    padding-bottom: 48px;
  }
`

const HeroHeading = styled.div`
  font-size: 50px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -1.11px;
  color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  padding: 16px 0;
  text-shadow: 0 2px 7px rgba(113, 113, 113, 0.6);
  @media (max-width: 1025px) {
    font-size: 30px;
    font-weight: 500;
    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  }
`

const HeroSub = styled.div`
  width: 650px;
  height: 96px;
  margin: 7px 14px 32px;
  font-family: Objectivity;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.21px;
  text-align: center;
  text-shadow: 0 2px 7px rgba(113, 113, 113, 0.6);
  ${(props) => (props.color ? `color: rgb(var(--${props.color}));` : '')}

  @media (max-width : 1025px) {
    width: 372px;
    height: 67px;
    margin: 7px 10px 25px;
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: -0.16px;
  }
  @media (min-width: 1920px) {
    width: 699px;
    height: 127px;
    margin: 10px 18px 37px;
    opacity: 0.85;
    font-family: Objectivity;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.27px;
    text-align: center;
    color: #ffffff;
  }
  @media (max-width: 768px) {
    width: 296px;
    height: 55px;
    opacity: 0.85;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: -0.12px;
    margin: 7px 10px 0px;
  }
`

const AbsoluteCover = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  @media (min-width: 1025px) {
    align-items: flex-start;
    justify-content: center;
  }
`

const MobileSpancer = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`

const BlueBanner = ({ image, imageDesktop, short, screenWidth }) => {
  const imageData = useStaticQuery(graphql`
    query {
      BannerDesktop: file(relativePath: { eq: "daybreak/banner-image.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      BannerMobile: file(relativePath: { eq: "daybreak/banner-mobile.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <BlueBannerContainer short={short}>
      <>
        <HeroDesktop
          loading="lazy"
          fluid={imageData.BannerDesktop.childImageSharp.fluid}
          alt=""
        />
        <HeroMobile
          loading="lazy"
          fluid={imageData.BannerMobile.childImageSharp.fluid}
          alt=""
        />
      </>
      <AbsoluteCover>
        <TextContainer>
          <TextTopContainer>
            <HeroHeading color="white">A breath of fresh air.</HeroHeading>
            <HeroSub>
              Introducing the Daybreak range. <br />
              Escape the daily grind for your next moment of inspiration.
            </HeroSub>
          </TextTopContainer>
        </TextContainer>
      </AbsoluteCover>
    </BlueBannerContainer>
  )
}

export default BlueBanner
